.projectContainer {
  padding: 30px;
}
.CreateBtn {
  color: var(--Neutral-0, #f6f7f9);

  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  text-transform: capitalize;
  border-radius: 8px;
  background: var(--Neutral-8, #222831);
  padding: 12px 20px 12px 16px;
}

.agent__create-btn {
  background: #5f6fff;
}

.banner-image {
  border-radius: 2%;
}
