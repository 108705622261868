.cardTitle {
  overflow: hidden;
  color: var(--Neutral-0, #f6f7f9);
  text-overflow: ellipsis;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  text-transform: capitalize;
}

.usersText {
  color: var(--Neutral-4, #637b94);
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.13px;
}
.description {
  overflow: hidden;
  color: var(--Neutral-4, #637b94);
  text-overflow: ellipsis;
  white-space: nowrap;

  /* Manrope 14 Regular */
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 19.6px */
  letter-spacing: -0.14px;
}
.chatButton {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 14px;
  font-style: normal;
  font-weight: 500;
  line-height: normal;
  letter-spacing: -0.14px;
  text-transform: capitalize;
  border-radius: 8px;
  background: var(--Neutral-8, #222831);
  padding: 12px 40px 12px 40px;
}

.agentSetting {
  border-radius: 8px;
  background: var(--Neutral-8, #222831);
  display: flex;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
}

.agentName {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.16px;
  text-transform: capitalize;
}
.position {
  color: var(--Neutral-2, #aebccb);
  font-family: Manrope;
  font-size: 11px;
  font-style: normal;
  font-weight: 300;
  line-height: normal;
  letter-spacing: -0.11px;
}
