.message-container {
  .message-content-container {
    width: 1100px;
  }

  .message-ingest-container {
    width: 480px;
  }

  @media (max-width: 767px) {
    .message-content-container {
      width: 900px;
    }

    .message-ingest-container {
      width: 400px;
    }
  }
}

.textarea {
  display: block;
  width: 100%;
  overflow: hidden;
  resize: both;
  min-height: 40px;
  line-height: 20px;
}

.textarea[contenteditable]:empty::before {
  content: 'Placeholder still possible';
  color: gray;
}

.chat__message_wrapper {
  height: 100vh;
}

.analyzing_lists {
  li {
    &:not(:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4)) {
      display: none;
    }
  }

  &.is-active {
    li {
      &:not(:nth-child(1), :nth-child(2), :nth-child(3), :nth-child(4)) {
        display: flex;
      }
    }
  }
}

.arrow-icon {
  img {
    transition: .3s linear 0s;
    
  }
  &.active {
    img {
      transform: rotate(180deg);

    }
  }
}