.subscription-package-container {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  justify-content: center;
  margin-top: 32px;
}

.cardContainer {
  display: flex;
  gap: 4px;
  border: 1px solid var(--Neutral-8, #222831);
  border-radius: 24px;
  background: rgba(255, 255, 255, 0.03);
  padding: 40px;
  flex-direction: column;
  justify-content: space-between;
}

.packageName {
  color: var(--White, #fff);
  font-family: Manrope;
  font-size: 24px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
}

.description {
  color: var(--Neutral-3, #8297ae);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}
.packagePrice {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 48px;
  font-style: normal;
  font-weight: 700;
  line-height: normal;
  letter-spacing: -0.96px;
}

.benefitsText {
  color: var(--Neutral-3, #8297ae);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
}
.buyButton {
  display: flex;
  padding: 10px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  border: 1px solid var(--Accent-2---Defauult, #5f6fff);
  margin-top: 4px;
}

@media (min-width: 640px) {
}

@media (min-width: 768px) {
  .subscription-package-container {
    flex: 1 1 calc(50% - 0.5rem);
  }

  .cardContainer {
    border: 1px solid var(--Neutral-8, #222831);
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.03);
    width: 250px;
    padding: 15px;
  }
}

@media (min-width: 1366px) {
  .subscription-package-container {
    flex: 1 1 calc(25% - 0.75rem);
  }

  .cardContainer {
    border: 1px solid var(--Neutral-8, #222831);
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.03);
    width: 250px;
    padding: 25px;
  }
}

@media (min-width: 1920px) {
  .cardContainer {
    border: 1px solid var(--Neutral-8, #222831);
    border-radius: 24px;
    background: rgba(255, 255, 255, 0.03);
    min-width: 350px;
    padding: 40px;
  }
}
