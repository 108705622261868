.newwark__create-agent-form {
  width: 680px;
  padding: 36px 48px 48px 48px;
  border-radius: 12px;
  // border: 1px solid #222831;
  position: fixed;
  left: calc(50% + 148px);
  top: 50%;
  transform: translate(-50%, -50%);
  height: 530px;
  overflow-x: auto;
  overflow-y: scroll;
  scrollbar-width: thin;
  scrollbar-color: #222 #222831;

  @media (max-width: 767px) {
    width: 90%;
    margin: 30px 0;
    padding-left: 30px;
    padding-right: 30px;
  }

  .create-agent-form {
    .form-field {
      line-height: 21.86px;
    }
  }

  .upload-image {
    position: relative;
    width: 120px;
  }

  .file-upload-box {
    border: 1px dashed #323c48;
    padding: 48px 30px 48px;
  }

  .select-model {
    select {
      appearance: none;
      background: url('../../../public/icons/arrow-down.svg');
      background-repeat: no-repeat;
      background-position: calc(100% - 22px);
    }
  }

  // Radio button
  .agent-type {
    .single-type {
      input[type='radio'] {
        appearance: none;
        width: 20px;
        height: 20px;
        border: 5px solid #384454;
        border-radius: 50%;
        outline: none;
        transition: box-shadow 0.3s ease;

        &::before {
          content: '';
          display: block;
          width: 60%;
          height: 60%;
          margin: 20% auto;
          border-radius: 50%;
        }

        &:checked:before {
          background: #5f6fff;
        }

        &:checked {
          border: 3px solid #5f6fff;
        }

        &:checked + span {
          color: #fff;
        }
      }
    }
  }

  // Agent category
  .agent-category {
    padding: 10px !important;

    .agent-cagetory__control {
      background: transparent;
      border: 0;

      .agent-cagetory__multi-value {
        background: #323c48;
        border-radius: 35px;
        padding: 3px 10px;
        display: flex;
        align-items: center;
        gap: 4px;
      }

      .agent-cagetory__multi-value__label {
        color: #fff;
      }

      .agent-cagetory__multi-value__remove {
        background: #4e637b;
        border-radius: 50%;
        width: 18px;
        height: 18px;
      }

      .agent-cagetory__indicator-separator {
        display: none;
      }

      .agent-cagetory__dropdown-indicator {
        svg {
          color: #384454;
        }
      }
    }

    .agent-cagetory__menu {
      background: transparent;
      border: 0;
    }
  }

  /*===========================
    Button Style
    ===========================*/
  .button-area {
    @media (max-width: 767px) {
      display: block;
    }

    .btn-item {
      @media (max-width: 767px) {
        flex-basis: 100%;
      }

      &:hover {
        background: #4d5cea;
        color: #ffffff;
      }

      &.create-btn {
        &:hover {
          background: #4d5cea;
        }
      }
    }
  }
}
.custom-option {
  background-color: #4b5563; // Tailwind's bg-gray-600
  color: #ffffff;
}

.agent-cagetory__input {
  color: white !important;
}
