.agent-edit-container {
  overflow: auto scroll;
  max-height: 650px;
  scrollbar-width: thin;
  scrollbar-color: #222 #222831;
}

.file-upload-box {
  border: 1px dashed #323c48;
  padding: 10px;
}

.agent-category {
  padding: 10px !important;

  .agent-category__control {
    background: transparent;
    border: 0;

    .agent-category__multi-value {
      background: #323c48;
      border-radius: 35px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .agent-category__multi-value__label {
      color: #fff;
    }

    .agent-category__multi-value__remove {
      background: #4e637b;
      border-radius: 50%;
      width: 18px;
      height: 18px;
    }

    .agent-category__indicator-separator {
      display: none;
    }

    .agent-category__dropdown-indicator {
      svg {
        color: #384454;
      }
    }
  }

  .agent-category__menu {
    background: transparent;
    border: 0;
  }
}
