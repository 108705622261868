.message__sidebar-wrapper {
  .single-item {
    p {
      overflow: hidden;
      width: 100%;
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
    }

    &:hover {
      p {
        color: #f6f7f9;
      }
    }
  }
  .active-user-card {
    border-left: solid #769af5;
  }
}
