// Agent category
.user-tags {
  padding: 10px !important;

  .tags__control {
    background: transparent;
    border: 0;

    .tags__multi-value {
      background: #323c48;
      border-radius: 35px;
      padding: 3px 10px;
      display: flex;
      align-items: center;
      gap: 4px;
    }

    .tags__multi-value__label {
      color: #fff;
    }

    .tags__multi-value__remove {
      background: #4e637b;
      border-radius: 50%;
      width: 18px;
      height: 18px;
    }

    .tags__indicator-separator {
      display: none;
    }

    .tags__dropdown-indicator {
      svg {
        color: #384454;
      }
    }
  }

  .tags__menu {
    background: transparent;
    border: 0;
  }
}

// Radio button
.user-type {
  .single-type {
    input[type='radio'] {
      appearance: none;
      width: 20px;
      height: 20px;
      border: 5px solid #384454;
      border-radius: 50%;
      outline: none;
      transition: box-shadow 0.3s ease;

      &::before {
        content: '';
        display: block;
        width: 60%;
        height: 60%;
        margin: 20% auto;
        border-radius: 50%;
      }

      &:checked:before {
        background: #5f6fff;
      }

      &:checked {
        border: 3px solid #5f6fff;
      }

      &:checked + span {
        color: #fff;
      }
    }
  }
}
