.CommunityText {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.16px;
  text-transform: capitalize;
}

.projects {
  color: var(--Neutral-4, #637b94);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.16px;
  padding: 10px 12px;
}
.projectHeaderTitle {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 20px;
  font-style: normal;
  font-weight: 600;
  line-height: normal;
  letter-spacing: -0.2px;
  text-transform: capitalize;
}

.main-content {
  flex: 1; /* Take up remaining width */
  padding-left: 0; /* Add padding if needed */
  overflow-y: auto; /* Make the main content scrollable */
  position: relative;
}

.sidebar {
  width: 296px; /* Width of the sidebar (e.g., 64px) */
  height: 100vh; /* Full viewport height */
  position: sticky;
  top: 0;
  left: 0;
  background: var(--Neutral-850, #191f28);
  z-index: 1; /* Ensure the sidebar is above the main content */
  overflow: auto; /* Make the sidebar scrollable if its content overflows */
}
.scrollable {
  min-height: 100px;
  max-height: 200px;
  overflow: auto;
  scrollbar-width: none; /* Firefox */
  -ms-overflow-style: none; /* Internet Explorer 10+ */
}

/* Hide scrollbar for Webkit-based browsers (Chrome, Safari) */
.scrollable::-webkit-scrollbar {
  width: 0px;
  background: transparent; /* Optional: just to avoid it being visible */
}

.sub-plan {
  right: 0;
  top: 10px;
  background: blue;
  padding: 0px 9px;
  border-radius: 4px;
  font-size: 11px;
  background: var(--Gradient, linear-gradient(90deg, #5fbcff 0%, #5f6fff 100%));
}
