.modal-default {
  width: 90%;
  max-width: 500px;
}

@media (min-width: 640px) {
  .modal-default {
    width: 80%;
    max-width: 600px;
  }
}

@media (min-width: 768px) {
  .modal-default {
    width: 70%;
    max-width: 700px;
  }
}

@media (min-width: 1024px) {
  .modal-default {
    width: 60%;
    max-width: 800px;
  }
}
