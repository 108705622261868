.main-container {
  width: 640px;
  /* height: 100vh; */
  position: absolute;
  right: 0;
  opacity: 0.8;
  background-color: #111523;
}

.transparent-input {
  background-color: transparent !important;
}

.error-text {
  color: var(--Alert, #ff564b);
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.13px;
}

.loginBtn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Neutral-7, #4d5dd1);
}

.loginBtn:hover {
  background: var(--Neutral-7, #6171e8);
}

.registrationBtn {
  display: flex;
  padding: 10px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  border-radius: 12px;
  background: var(--Neutral-7, #4d5dd1);
}

.registrationBtn:hover {
  background: var(--Neutral-7, #6171e8);
}

.eyeIcon {
  top: 18px;
  right: 20px;
  cursor: pointer;
}

.createAccountText {
  color: var(--Neutral-3, #8297ae);
  text-align: center;
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 125%; /* 20px */
  letter-spacing: 0.16px;
}
.loginText {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 16px;
  font-style: normal;
  font-weight: 700;
  line-height: 125%;
  letter-spacing: 0.16px;
  margin-left: 2px;
}

@media screen and (width <= 475px) {
  .login-page-content,
  .register-page-content {
    width: 100%;
    height: 100vh;
    position: relative;
    background-color: #11152382;

    > div {
      margin-top: 0;
    }

    > div:first-child {
      top: 10px;
      left: 50%;
      margin-top: 0;
      position: absolute;
      transform: translateX(-50%);
    }

    > div:last-child,
    > div:nth-child(2) {
      top: 50%;
      left: 50%;
      width: 100%;
      position: absolute;
      transform: translate(-50%, -50%);
    }

    .AuthBody {
      width: 100%;
      padding: 0 12%;

      > div {
        width: 100%;
      }

      form > div > div > label {
        font-size: 18px;
      }
    }
  }

  .login-page-content {
    > div:nth-child(2) {
      transform: translate(-50%, calc(50% - 270px));

      .AuthHeader {
        font-size: 30px;
      }
    }
  }

  .register-page-content {
    > div:last-child {
      transform: translate(-50%, -40%);
    }

    > div:nth-child(2) {
      transform: translate(-50%, calc(50% - 322px));
    }
  }
}

@media screen and (height <= 675px) {
  .login-page-content {
    > div:last-child {
      transform: translate(-50%, -40%);
    }

    > div:nth-child(2) {
      transform: translate(-50%, calc(40% - 225px));
    }
  }

  .register-page-content {
    > div:nth-child(2) {
      transform: translate(-50%, calc(50% - 290px));
    }

    .AuthBody {
      > div:first-child {
        top: 10px;
      }

      form {
        button[type='submit'] {
          margin-top: 20px;
        }

        > div:nth-child(-n + 4) {
          margin-bottom: 10px;
        }

        > div > div > label {
          margin: 0;
        }
      }

      .createAccountText {
        font-size: 14px;
      }
    }
  }
}
