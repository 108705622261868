@tailwind base;
@tailwind components;
@tailwind utilities;

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
    'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.common-scrollbar-thin {
  overflow: auto scroll;
  scrollbar-width: thin;
  scrollbar-color: #464545 #222831;
}

.AuthHeader {
  font-family: Manrope;
  font-size: 25px;
  font-weight: 700;
  line-height: 43.71px;
  letter-spacing: -0.02em;
  text-align: center;
  color: #f6f7f9;
}

.Manrope {
  font-family: 'Manrope', sans-serif;
}

.AuthBody {
  width: 500px;
}
.InputLabel {
  color: var(--Neutral-0, #f6f7f9);
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.13px;
}

.Input {
  padding: 10px;
  border-radius: 10px;
  border: 1px solid var(--Neutral-8, #222831);
}

.ErrorInput {
  border: 1px solid var(--Alert, #ff564b);
}

.main-container {
  width: 640px;
  /* height: 100vh; */
  position: relative;
  right: 0;
  opacity: 0.8;
  background-color: #111523;
}

.transparent-input {
  background-color: transparent !important;
}

.error-text {
  color: var(--Alert, #ff564b);
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: normal;
  letter-spacing: -0.13px;
}

.submitBtn {
  display: flex;
  padding: 20px 32px;
  justify-content: center;
  align-items: center;
  gap: 8px;
  align-self: stretch;
  border-radius: 12px;
  background: var(--Neutral-7, #5f6fff);
  color: var(--Neutral-3, #fff);
}

.eyeIcon {
  top: 28px;
  right: 20px;
  cursor: pointer;
}

.splitter {
  height: 1px;
  flex-grow: 3;
  background: var(--Neutral-8, #222831);
}

.continueText {
  color: var(--Neutral-3, #8297ae);
  font-family: Manrope;
  font-size: 13px;
  font-style: normal;
  font-weight: 400;
  line-height: 140%; /* 18.2px */
  letter-spacing: 0.13px;
}

.socialContainer {
  padding: 10px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid var(--Neutral-8, #222831);
  border-radius: 12px;
}

.socialIcon {
  width: 18.174px;
  height: 19px;
}

.custom-scroll {
  scrollbar-width: thin;
  scrollbar-color: #222 #222831;
}

.even-child-m-8 > *:nth-child(even) {
  margin-top: 8px;
}
