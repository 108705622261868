.message__wrapper {
  height: 100vh;
  position: relative;

  .message__search-section {
    position: absolute;
    left: 0;
    right: 0;
    bottom: 46px;
    padding: 0 50px;
  }
}
