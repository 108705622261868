.markdown-container {
  font-family: Arial, sans-serif;
  line-height: 1.6;
}

.markdown-container pre {
  overflow-x: auto;
  background-color: #161b22;
  padding: 1em;
  border-radius: 5px;
  white-space: pre-wrap;
  word-wrap: break-word;
}

.markdown-container code {
  font-family: 'Courier New', Courier, monospace;
  overflow-x: hidden;
}
