.message-right {
  margin-left: 22%;

  .right__message-text {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      right: -10px;
      width: 0;
      border-top: 19px solid transparent;
      border-bottom: 19px solid transparent;
      border-right: 19px solid #222831;
      top: -12px;
      transform: rotate(45deg);
    }
  }
}

.neu-ingest {
  display: flex;

  .neu-file-link {
    margin-right: 5px;

    padding: 3px 5px;
    text-decoration: none;
    display: flex;
    gap: 2px;
    background-color: rgb(34 40 49);
    border-radius: 2px;
    cursor: pointer;

    img {
      height: 20px;
      width: 20px;
    }
  }
}

.toggle-container {
  .switch-btn {
    position: relative;
    display: inline-block;
    width: 36px;
    height: 20px;

    input {
      cursor: pointer;
      display: block;
      height: 100%;
      left: 0;
      opacity: 0;
      position: absolute;
      top: 0;
      width: 100%;
      z-index: 6;
    }

    .switch {
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      bottom: 0;
      background-color: #222831;
      transition: 0.4s;
      border-radius: 50px;

      &:before {
        position: absolute;
        content: '';
        height: 18px;
        width: 18px;
        left: 1px;
        bottom: 1px;
        background-color: #4e637b;
        transition: 0.4s;
        border-radius: 50%;
      }
    }

    input:checked + .switch {
      background-color: #5f6fff;
    }

    input:checked + .switch:before {
      transform: translateX(15px);
      background-color: #f6f7f9;
    }
  }
}

.message__wrapper {
  .messages {
    .message-item {
      max-width: 67%;
    }
  }
}
